import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "「フロントエンド開発に再入門する」タスクフォースの進め方",
  "date": "2017-11-09T08:10:47.000Z",
  "slug": "entry/2017/11/09/171047",
  "tags": ["medley"],
  "hero": "./2017_11_09.png",
  "heroAlt": "frontend taskforce"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`こんにちは。開発本部の`}<a parentName="p" {...{
        "href": "https://www.wantedly.com/companies/medley/post_articles/63206"
      }}>{`宍戸`}</a>{`です。
メドレーでは定期的に、テーマに沿って組織の技術的な底上げを行うための機会（`}<a parentName="p" {...{
        "href": "https://developer.medley.jp/entry/2017/10/20/122000"
      }}>{`タスクフォース`}</a>{`と呼んでいます）を行っています。そのタスクフォースの１つとして先日、フロントエンド開発力のベースアップを目的としたタスクフォースを行いました。本記事では、その取組みについてご紹介したいと思います。`}</p>
    <h1>{`背景`}</h1>
    <p>{`メドレーには現在 20 人弱のエンジニアが在籍しており、その約半数がサーバーサイド出身者です。また普段の開発においては、一つの機能をフロントからサーバーサイドまで一貫して一人が担当するケースが多くあります。サーバーサイド出身者のフロントエンド開発のスキルセットには多少ばらつきはあるものの、普段の開発業務ではレビュー等でそれぞれサポートしつつ開発を行っています。`}</p>
    <p>{`しかし、フロントエンドの基礎的な部分や最新の流れまで聞かれると不安なメンバーも少なくありません。フロントエンド出身のメンバーが主導し、改めて基礎や最新情報に関して整理・フォローを行うことで、組織全体のフロントエンドの開発力を高めていきたいと考えました。`}</p>
    <h1>{`タスクフォースの目的`}</h1>
    <p>{`今回のタスクフォースは『フロントエンドの基本や最近のトレンドに関して学ぶ』ことで『（フロントエンド開発における）技術選定、設計、実装ができる基礎を身につける』、そしてこれらをもとに『新規のプロジェクトで設計段階から自走できるようになる』ことを目的としました。`}</p>
    <p>{`その中でも特にここ数年、変化の流れが早かった JavaScript を中心にトピックを選定しました。`}</p>
    <p>{`参加者は、これまでサーバーサイド開発を中心に行ってきたメンバー数名です。背景でも触れたとおり、業務経験はそれぞれある前提でのスタートであったため、基礎をみっちりというよりは、基礎的な話から最近の話題までを一通り確認しながら、各自の持っている知識の整理と土台を固めることで、今後の設計や技術選定を行う際の指針を得ることを目的としました。`}</p>
    <h1>{`進め方`}</h1>
    <p>{`今回のタスクフォースは期間を 3 ヶ月と定め、毎週 1 時間程度集まって行いました。`}</p>
    <p>{`毎週、事前に講師陣が選んだ資料を読んでおき、当日は講師陣が参加者の不明点、疑問点に対してフォローアップするという形式で進めました。その他には、社内のプロダクトでの利用事例なども交えながらテーマに関する質問会のような形で進むことが多かったです。`}</p>
    <p>{`また毎回のタスクフォースの時間のあとに、参加者がその日の内容をまとめた議事録形式の資料を作成し、参加者全員と共有することで、その日に話された内容や、それぞれの理解度を再度確認するようにしました。`}</p>
    <h1>{`内容`}</h1>
    <p>{`およその流れは上記の通りですが、約 3 ヶ月でどのようなテーマに触れてきたのか、一部をご紹介します。`}</p>
    <h2>{`フロントエンドの基礎`}</h2>
    <p>{`序盤はこちらの資料を利用させていただきながら進めました。`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.html5rocks.com/ja/tutorials/internals/howbrowserswork/"
        }}>{`ブラウザの仕組み: 最新ウェブブラウザの内部構造`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://speakerdeck.com/rtechkouhou/css?slide=22"
        }}>{`Asg Boot Camp 　 HTML/CSS`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://javascript.info/intro"
        }}>{`An Introduction to JavaScript`}</a></li>
    </ul>
    <p>{`ブラウザの仕組み、HTML/CSS の基本的な話のおさらい、JavaScript の話に関連して、これまでに出てきた`}<a parentName="p" {...{
        "href": "https://github.com/jashkenas/coffeescript/wiki/List-of-languages-that-compile-to-JS"
      }}>{`AltJS`}</a>{`についてもいくつか特徴や何故流行ったのかなどについて読み込みました。`}</p>
    <p>{`この中でも、`}<a parentName="p" {...{
        "href": "https://www.html5rocks.com/ja/tutorials/internals/howbrowserswork/"
      }}>{`ブラウザの仕組み: 最新ウェブブラウザの内部構造`}</a>{`で DOM の解析、レンダリング、レイアウトといったブラウザ内部で具体的に何が行われているのかといった話はここで確認できてよかったという声が多くありました。`}</p>
    <h2>{`JavaScript（基礎〜ES2015 以降）`}</h2>
    <p>{`JavaScript の話題への導入編としてこちらを資料として読み込みました。`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/getify/You-Dont-Know-JS/blob/master/up%20%26%20going/ch2.md"
        }}>{`You Don't Know JS: Up & Going`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://bonsaiden.github.io/JavaScript-Garden/ja/"
        }}>{`JavaScript Garden`}</a></li>
    </ul>
    <p>{`このパートでは JavaScript の基礎は、あえて ES3〜5 をベースにすることで JavaScript と他言語との違い・特徴を再確認していきました。上記の内容を踏まえ、今では使わない書き方などについてはその理由も確認しながら進めていきました。`}</p>
    <p>{`その後は`}<a parentName="p" {...{
        "href": "https://babeljs.io/learn-es2015/"
      }}>{`Learn ES2015 · Babel`}</a>{`を参照しながら、Promise, Class などは普段の開発でも当たり前のように利用しているものの、ES2015 以降での書き方は ES5 だとどのようになっていたかもここで同時に学習していきました。（`}<a parentName="p" {...{
        "href": "https://www.typescriptlang.org/play/"
      }}>{`Playground ・ TypeScript`}</a>{`で、その雰囲気を見ることが出来ます）`}</p>
    <img {...{
      "width": 1378,
      "alt": "Screen Shot 2017-10-27 at 16.14.49.png (424.4 kB)",
      "src": "https://img.esa.io/uploads/production/attachments/6223/2017/10/27/2208/d03f2bd8-4c1c-4b61-add7-aa873d6e2788.png"
    }}></img>
    <h2>{`モダン JavaScript`}</h2>
    <p>{`これまでの知識を踏まえ、モダンな JavaScript の利用(実装)例として、`}<a parentName="p" {...{
        "href": "https://github.com/jser/jser.github.io"
      }}>{`jser.github.io`}</a>{`と`}<a parentName="p" {...{
        "href": "https://github.com/developit/preact-www/"
      }}>{`preact-www`}</a>{`のソースを読んでいきました。`}</p>
    <p>{`これまでの JavaScript の言語自体に関する内容から、ライブラリを利用したコンポーネント間でのデータフローや、コンポーネントのライフサイクルに関する部分まで確認していきました。また初見のプロジェクトであればどのあたりから目を通していくか、などコード全体の読み方についても講師陣からアドバイスがありました。また時折出てくる DOM API に「なんだっけこれ・・・？」などとなりつつもコードを紐解いていくことで改めてフロントエンド JavaScript の特徴的な部分を垣間見ることができたように思います。`}</p>
    <p>{`また最後に、現在開発に利用されているツール群について、`}<a parentName="p" {...{
        "href": "https://qiita.com/azu/items/2921f62127b8d3a1aa03"
      }}>{`Qiita:JavaScript フレームワーク選定の議論`}</a>{`を参考に確認しました。それぞれのツールがどのような背景で使われている（あるいはいない）のかなども合わせて確認をしました。`}</p>
    <p>{`ここまでのテーマを振り返りつつ、JavaScript が言語としてどのように変化してきたかを考えた時、webpack や TypeScript がなぜ使われるのか、ようやく腹に落ちたように思います。また上記資料も、どのようなケースで何を選択するのかや、アプリケーションの寿命とライブラリやツールの寿命といった運用フェーズで理解していく必要のある事項にも触れられており、非常に参考になりました。`}</p>
    <h1>{`実際にやってみて`}</h1>
    <p>{`今回のタスクフォースでは、3 ヶ月という期間の中で、1 ヶ月半の時点と、全体終了後に振り返りを実施しました。
その中で、参加メンバーからは`}</p>
    <ul>
      <li parentName="ul">{`業務においては必要な部分に絞った調査で終わってしまったり、周りのコードを参考にしたりすることでなんとなくできた気になってしまっていたが、今回改めて基礎的な知識を学習する、復習する時間としてできてよかった。`}</li>
      <li parentName="ul">{`断片的でまばらな知識しか持っていなかった部分が資料を読み込むことで理解が深まった`}</li>
      <li parentName="ul">{`数年前にバズってあとで読まない「あとで読む」ブクマ記事をきちんと読めてよかった`}</li>
      <li parentName="ul">{`フロントエンド経験の長い講師陣の生きた知見（ツラミなども含めて）を聞くことができたのはありがたかった`}</li>
    </ul>
    <p>{`などの感想が出ました。
私自身も参加していましたが、個人的には基礎部分を改めて固めることができた機会だったように感じています。
また、普段は新しいツールなどの情報のキャッチアップへの意識が向きがちですが、今回のタスクフォースで言語・ツールの変遷を一度通して見ることができたことで、新しく現れる技術がどういった課題を解決するものなのか、これまでに似たツールがあったのかどうかなどを調べる指針が得られたことはよかったのかなと思っています。`}</p>
    <p>{`一方で、今回のタスクフォースの中では「コードを書く」時間は作りませんでした。これについては振り返りの中でも話題となりましたが、それについては`}<a parentName="p" {...{
        "href": "https://github.com/tastejs/todomvc"
      }}>{`TodoMVC`}</a>{`などを参考に、自分で手を動かしてみることが必要だろうと今後の課題として挙げられました。このあたりは実務以外での個々の頑張りが必要になってくる部分かと思います。`}</p>
    <h1>{`まとめ`}</h1>
    <p>{`「サーバーサイドエンジニアのフロントエンド開発力の底上げ」をテーマとしたタスクフォースについてご紹介しました。こういった形で、同じようなスキルセットのメンバーが集まり、お互いにわからない部分について話をしたり、経験豊富なメンバーから、個人の経験を含めて話を聞くというのは、改めて非常に貴重な機会だったように思います。`}</p>
    <p>{`こういった取り組みを繰り返していくことで、個々で尖った部分はもちろん伸ばしつつ、全体の底上げを行いながら、よりよいプロダクト開発に繋げられればと考えています。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      